import {
  Alpine as AlpineIcon,
  Cargo as CargoIcon,
  Cocoapods as CocoapodsIcon,
  Composer as ComposerIcon,
  Conan as ConanIcon,
  Conda as CondaIcon,
  Cran as CranIcon,
  Dart as DartIcon,
  Deb as DebIcon,
  Docker as DockerIcon,
  Go as GoIcon,
  Helm as HelmIcon,
  Hex as HexIcon,
  Luarocks as LuarocksIcon,
  Maven as MavenIcon,
  Npm as NpmIcon,
  Nuget as NugetIcon,
  Python as PythonIcon,
  Raw as RawIcon,
  Ruby as RubyIcon,
  Swift as SwiftIcon,
  Terraform as TerraformIcon,
  Vagrant as VagrantIcon,
} from '@cloudsmith/icons/formats';

import {
  Alpine as AlpineLogo,
  Rust as RustLogo,
  Cocoapods as CocoapodsLogo,
  Composer as ComposerLogo,
  Conan as ConanLogo,
  Conda as CondaLogo,
  Cran as CranLogo,
  Dart as DartLogo,
  Debian as DebLogo,
  Docker as DockerLogo,
  Go as GoLogo,
  Helm as HelmLogo,
  Lua as LuaLogo,
  Maven as MavenLogo,
  Npm as NpmLogo,
  Nuget as NugetLogo,
  Python as PythonLogo,
  Redhat as RedhatLogo,
  Ruby as RubyLogo,
  Swift as SwiftLogo,
  Terraform as TerraformLogo,
  Vagrant as VagrantLogo,
} from '@cloudsmith/icons/logos';

export const FORMAT_ICONS = {
  alpine: AlpineIcon,
  cargo: CargoIcon,
  cocoapods: CocoapodsIcon,
  composer: ComposerIcon,
  conan: ConanIcon,
  conda: CondaIcon,
  cran: CranIcon,
  dart: DartIcon,
  deb: DebIcon,
  docker: DockerIcon,
  go: GoIcon,
  helm: HelmIcon,
  hex: HexIcon,
  luarocks: LuarocksIcon,
  maven: MavenIcon,
  npm: NpmIcon,
  nuget: NugetIcon,
  python: PythonIcon,
  raw: RawIcon,
  ruby: RubyIcon,
  swift: SwiftIcon,
  terraform: TerraformIcon,
  vagrant: VagrantIcon,
};

export const FORMAT_LOGOS = {
  alpine: AlpineLogo,
  cargo: RustLogo,
  cocoapods: CocoapodsLogo,
  composer: ComposerLogo,
  conan: ConanLogo,
  conda: CondaLogo,
  cran: CranLogo,
  dart: DartLogo,
  deb: DebLogo,
  docker: DockerLogo,
  go: GoLogo,
  helm: HelmLogo,
  luarocks: LuaLogo,
  maven: MavenLogo,
  npm: NpmLogo,
  nuget: NugetLogo,
  python: PythonLogo,
  rpm: RedhatLogo,
  ruby: RubyLogo,
  swift: SwiftLogo,
  terraform: TerraformLogo,
  vagrant: VagrantLogo,
};

export const FORMATS = {
  ALPINE: 'alpine',
  CARGO: 'cargo',
  COCOAPODS: 'cocoapods',
  COMPOSER: 'composer',
  CONAN: 'conan',
  CONDA: 'conda',
  CRAN: 'cran',
  DART: 'dart',
  DEB: 'deb',
  DOCKER: 'docker',
  GO: 'go',
  HELM: 'helm',
  HEX: 'hex',
  LUAROCKS: 'luarocks',
  MAVEN: 'maven',
  NPM: 'npm',
  NUGET: 'nuget',
  P2: 'p2',
  PYTHON: 'python',
  RPM: 'rpm',
  RUBY: 'ruby',
  SWIFT: 'swift',
  TERRAFORM: 'terraform',
  VAGRANT: 'vagrant',
  RAW: 'raw',
};
