'use client';

import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Info, QuestionMark } from '@cloudsmith/icons';

import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';

import styles from './Explainer.module.css';

const Explainer = ({
  className,
  explainer,
  inline = false,
  variant = 'question',
  delayDuration,
}) => {
  return (
    <Tooltip delayDuration={delayDuration}>
      <TooltipTrigger asChild>
        <span
          className={cn(styles.explainerTrigger, className)}
          data-inline={inline}
          data-variant={variant}>
          {variant === 'question' && <QuestionMark />}
          {variant === 'information' && <Info />}
        </span>
      </TooltipTrigger>
      <TooltipContent>{explainer}</TooltipContent>
    </Tooltip>
  );
};

Explainer.propTypes = {
  className: PropTypes.string,
  explainer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['question', 'information']),
  inline: PropTypes.bool,
  delayDuration: PropTypes.number,
};

export default Explainer;
