'use client';

import React, { Children, useState, startTransition, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {
  Combobox as AriakitCombobox,
  ComboboxList as AriakitComboboxList,
  ComboboxProvider as AriakitComboboxProvider,
  ComboboxItem as AriakitComboboxItem,
  SelectProvider as AriakitSelectProvider,
  Select as AriakitSelect,
  SelectPopover as AriakitSelectPopover,
  SelectItem as AriakitSelectItem,
  SelectItemCheck as AriakitSelectItemCheck,
  SelectGroup as AriakitSelectGroup,
  SelectGroupLabel as AriakitSelectGroupLabel,
} from '@ariakit/react';

import { ChevronDown, Search, Tick } from '@cloudsmith/icons';

import { ensureArray } from '../../../util/array';
import Flex from '../../Flex';
import LoadingSpinner from '../../LoadingSpinner';
import Text from '../../Text';
import SelectValueOrPlaceholder from '../../../internal/SelectValueOrPlaceholder';
import VirtualizedSelectList from '../../../internal/VirtualizedSelectList';

import styles from './Combobox.module.css';

const COMBOBOX_DROPDOWN_POSITION = {
  AUTO: 'auto',
  ABOVE: 'above',
};

export const Combobox = React.forwardRef(
  (
    {
      children,
      customFilter,
      disabled,
      displayLimit,
      hasErrors,
      hasHint,
      hintId,
      id,
      isLoading = false,
      dropdownPosition = COMBOBOX_DROPDOWN_POSITION.AUTO,
      multiSelect,
      name,
      noResultsMessage = 'No results found',
      onSearch,
      onValueChange,
      placeholder,
      searchPlaceholder = 'Search...',
      selectAll,
      selectAllActive,
      selectAllLabel = 'Select all',
      size = 'm',
      value,
      variant = 'default',
      virtualizeEstimatedItemSize = 36,
      virtualizeList = false,
      ...props
    },
    forwardedRef,
  ) => {
    const [searchValue, setSearchValue] = useState('');

    const defaultFilter = (value, match) =>
      !match || value.toLowerCase().includes(match.toLowerCase());

    const filterFunction = customFilter || defaultFilter;

    const filteredChildren = useMemo(() => {
      let matchCount = 0;

      const filter = (children, searchValue, value) =>
        Children.map(children, (child) => {
          const isGroup = child.type === ComboboxGroup;
          const isItem = child.props.searchValue ?? child.props.value;

          if (isGroup) {
            return {
              ...child,
              props: {
                ...child.props,
                children: filter(child.props.children, searchValue, value),
              },
            };
          }
          if (
            isItem &&
            (filterFunction(
              child.props.searchValue ?? child.props.value,
              searchValue,
            ) ||
              /**
               * For multi select capable comboboxes we don’t want
               * to show the selected items in the search result
               * list–unless of course they match the search query.
               */
              (!multiSelect
                ? ensureArray(value).includes(child.props.value)
                : false))
          ) {
            matchCount++;
            return child;
          }
        }).flat();

      const matches = filter(children, searchValue, value);

      return matchCount > 0 ? matches : [];
    }, [children, searchValue, value, filterFunction, multiSelect]);

    const handleSetValue = (v) => {
      if (multiSelect) {
        onValueChange(ensureArray(v));
      } else {
        onValueChange(v);
      }
    };

    return (
      <div
        className={styles.root}
        data-size={size}
        data-variant={variant}
        data-dropdown-position={dropdownPosition}>
        <AriakitComboboxProvider
          resetValueOnHide
          setValue={(v) => {
            startTransition(() => {
              onSearch?.(v);
              setSearchValue(v);
            });
          }}>
          <AriakitSelectProvider
            value={multiSelect ? ensureArray(value) : value}
            setValueOnMove={false}
            setValue={handleSetValue}
            placement="bottom"
            name={name}>
            <AriakitSelect
              ref={forwardedRef}
              aria-invalid={hasErrors ?? null}
              aria-describedby={hasHint ? hintId : null}
              className={styles.trigger}
              data-size={size}
              data-variant={variant}
              disabled={disabled}
              id={id}
              {...props}>
              <SelectValueOrPlaceholder
                disabled={disabled}
                multiSelect={multiSelect}
                displayLimit={displayLimit}
                value={value}
                groupComponentName="ComboboxGroup"
                placeholder={
                  <span className={styles.triggerPlaceholder}>
                    {placeholder}
                  </span>
                }
                items={children}
              />
              <ChevronDown className={styles.triggerIcon} />
            </AriakitSelect>

            <AriakitSelectPopover
              className={styles.menu}
              data-variant={variant}
              data-size={size}
              flip={dropdownPosition === COMBOBOX_DROPDOWN_POSITION.AUTO}
              overflowPadding={0}
              unmountOnHide
              sameWidth>
              <div className={styles.comboboxWrapper}>
                <AriakitCombobox
                  autoFocus
                  autoComplete="none"
                  className={styles.combobox}
                  placeholder={searchPlaceholder}
                  onBlurCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
                <Search className={styles.comboboxIcon} />
                {selectAll && (
                  <div className={styles.selectAll}>
                    <div
                      className={styles.item}
                      onClick={selectAll}
                      aria-selected={selectAllActive || null}>
                      <span>{selectAllLabel}</span>
                      {selectAllActive && <Tick className={styles.itemIcon} />}
                    </div>
                  </div>
                )}
              </div>

              {filteredChildren.length ? (
                <>
                  {isLoading && (
                    <Flex
                      align="center"
                      justify="center"
                      gap="2xs"
                      className={styles.loading}>
                      <LoadingSpinner />
                      <Text size="s" color="secondary">
                        Loading
                      </Text>
                    </Flex>
                  )}

                  <AriakitComboboxList>
                    {virtualizeList && (
                      <VirtualizedSelectList
                        className={styles.listbox}
                        items={filteredChildren}
                        estimatedItemSize={virtualizeEstimatedItemSize}
                      />
                    )}
                    {!virtualizeList && (
                      <div className={styles.listbox}>{filteredChildren}</div>
                    )}
                  </AriakitComboboxList>
                </>
              ) : (
                <Flex align="center" justify="center" className={styles.empty}>
                  <Text size="s" color="secondary">
                    {noResultsMessage}
                  </Text>
                </Flex>
              )}
            </AriakitSelectPopover>
          </AriakitSelectProvider>
        </AriakitComboboxProvider>
      </div>
    );
  },
);

Combobox.displayName = 'Combobox';
Combobox.propTypes = {
  children: PropTypes.node.isRequired,
  customFilter: PropTypes.func,
  disabled: PropTypes.bool,
  displayLimit: PropTypes.number,
  dropdownPosition: PropTypes.oneOf([
    COMBOBOX_DROPDOWN_POSITION.AUTO,
    COMBOBOX_DROPDOWN_POSITION.ABOVE,
  ]),
  forceOptions: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  multiSelect: PropTypes.bool,
  name: PropTypes.string,
  noResultsMessage: PropTypes.string,
  onSearch: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  selectAll: PropTypes.func,
  selectAllActive: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  variant: PropTypes.oneOf(['default', 'unstyled']),
  virtualizeList: PropTypes.bool,
  virtualizeEstimatedItemSize: PropTypes.number,
};

export const ComboboxItem = React.forwardRef(
  (
    { className, children, disabled, value, searchValue, ...props },
    forwardedRef,
  ) => (
    <AriakitSelectItem
      {...props}
      ref={forwardedRef}
      className={cn(styles.item, className)}
      disabled={disabled}
      data-search-value={searchValue}
      value={value}
      render={<AriakitComboboxItem />}>
      {children}
      <AriakitSelectItemCheck
        className={styles.itemIcon}
        render={({ children, ...rest }) =>
          children ? <Tick {...rest} /> : null
        }
      />
    </AriakitSelectItem>
  ),
);

ComboboxItem.displayName = 'ComboboxItem';
ComboboxItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
};

export const ComboboxGroup = ({ children, label }) => {
  if (!children || Children.count(children) === 0) return null;

  return (
    <AriakitSelectGroup className={styles.group}>
      {label && (
        <AriakitSelectGroupLabel className={styles.groupLabel}>
          {label}
        </AriakitSelectGroupLabel>
      )}
      {children}
    </AriakitSelectGroup>
  );
};

ComboboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

ComboboxGroup.displayName = 'ComboboxGroup';
