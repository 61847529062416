import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './LogoSymbol.module.css';

const LogoSymbol = ({ className, variant = 'm', fill }) => {
  const width = 518;
  const height = 519;

  return (
    <svg
      className={cn(styles.root, className)}
      data-fill={fill}
      viewBox={`0 0 ${width} ${height}`}>
      {variant === 'xs' && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M415 242.236V276.791L276.333 415.014H241.667L103 276.791V242.236L241.667 104.014H276.333L415 242.236ZM258.998 331.243C298.738 331.243 330.954 299.131 330.954 259.518C330.954 219.905 298.738 187.793 258.998 187.793C219.258 187.793 187.042 219.905 187.042 259.518C187.042 299.131 219.258 331.243 258.998 331.243Z"
        />
      )}
      {variant === 's' && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M415 241.736V276.292L276.333 414.514H241.667L103 276.292V241.736L241.667 103.514H276.333L415 241.736ZM258.998 340.145C303.947 340.145 340.386 303.824 340.386 259.019C340.386 214.213 303.947 177.892 258.998 177.892C214.049 177.892 177.61 214.213 177.61 259.019C177.61 303.824 214.049 340.145 258.998 340.145Z"
        />
      )}
      {variant === 'm' && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M414.999 242.236V276.792L276.332 415.014H241.666L102.999 276.792V242.236L241.666 104.014H276.332L414.999 242.236ZM258.998 352.416C310.468 352.416 352.193 310.824 352.193 259.519C352.193 208.214 310.468 166.623 258.998 166.623C207.528 166.623 165.803 208.214 165.803 259.519C165.803 310.824 207.528 352.416 258.998 352.416Z"
        />
      )}
    </svg>
  );
};

LogoSymbol.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['xs', 's', 'm']),
  fill: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accent']),
};

export default LogoSymbol;
