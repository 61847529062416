import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixTabs from '@radix-ui/react-tabs';

import styles from './TabsTrigger.module.css';

const TabsTrigger = ({ children, className, value }) => {
  return (
    <RadixTabs.Trigger className={cn(styles.root, className)} value={value}>
      {children}
    </RadixTabs.Trigger>
  );
};

TabsTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default TabsTrigger;
