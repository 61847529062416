'use client';

import React from 'react';
import PropTypes from 'prop-types';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';

import asFormInput from '../../internal/asFormInput';

import styles from './RadioGroup.module.css';

const RadioGroup = React.forwardRef(
  (
    {
      children,
      columns = 2,
      hasErrors,
      hasHint,
      hintId,
      inputId,
      name,
      orientation = 'vertical',
      onValueChange,
      size = 's',
      value,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <RadixRadioGroup.Root
        ref={forwardedRef}
        className={styles.root}
        data-size={size}
        name={name}
        value={value}
        onValueChange={onValueChange}
        aria-invalid={hasErrors}
        aria-describedby={hasHint ? hintId : null}
        data-orientation={orientation}
        id={inputId}
        style={{ '--radio-group-columns': columns }}
        {...props}>
        {children}
      </RadixRadioGroup.Root>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.number,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical', 'columns']),
  size: PropTypes.oneOf(['s', 'm', 'l']),
  value: PropTypes.string,
};

export default asFormInput(RadioGroup);
