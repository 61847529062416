import * as React from 'react';

import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Copy } from '@cloudsmith/icons';

import { mergeRefs } from '../../util/functions';
import LoadingSpinner from '../LoadingSpinner';
import VisuallyHidden from '../VisuallyHidden';
import {
  ColumnsProvider,
  breakpointPropType,
  columnsPropType,
  useColumnBreakpoint,
  useVisibleColumns,
} from './ColumnsContext';

import styles from './Table.module.css';

const TableRoot = React.forwardRef((props, forwardedRef) => {
  const {
    caption = '',
    children,
    className,
    columns = [],
    layout = 'auto',
    size = 'm',
    style,
    ...rootProps
  } = props;

  const tableRef = React.useRef(0);
  return (
    <ColumnsProvider value={{ columns, tableRef }}>
      <div
        ref={forwardedRef}
        className={cn(styles.root, className)}
        data-size={size}
        data-layout={layout}
        data-contained={style?.minWidth}
        {...rootProps}>
        <table className={styles.rootTable} ref={tableRef} style={style}>
          <VisuallyHidden asChild>
            <caption>{caption}</caption>
          </VisuallyHidden>
          <colgroup>
            {columns.map((column, index) => (
              <col
                key={index}
                style={column.style}
                data-breakpoint={column.breakpoint}
              />
            ))}
          </colgroup>
          {children}
        </table>
      </div>
    </ColumnsProvider>
  );
});
TableRoot.displayName = 'Table';
TableRoot.propTypes = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columns: columnsPropType,
  layout: PropTypes.oneOf(['auto', 'fixed']),
  style: PropTypes.object,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

const TableHeader = React.forwardRef((props, forwardedRef) => (
  <thead
    {...props}
    ref={forwardedRef}
    className={cn(styles.tableHeader, props.className)}
  />
));
TableHeader.displayName = 'TableHeader';
TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const TableBody = React.forwardRef((props, forwardedRef) => (
  <tbody
    {...props}
    ref={forwardedRef}
    className={cn(styles.tableBody, props.className)}
  />
));
TableBody.displayName = 'TableBody';
TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const TableRow = React.forwardRef((props, forwardedRef) => {
  const {
    align,
    children,
    className,
    selectable = false,
    isProcessing = false,
    ...rowProps
  } = props;
  return (
    <tr
      {...rowProps}
      ref={forwardedRef}
      aria-busy={isProcessing || null}
      aria-selected={rowProps['aria-selected'] || null}
      className={cn(styles.tableRow, className)}
      data-selectable={selectable || null}
      data-processing={isProcessing || null}
      style={{ verticalAlign: align }}>
      {children}
      {isProcessing && (
        <td aria-hidden className={styles.tableRowProcessing}>
          <LoadingSpinner size="m" />
        </td>
      )}
    </tr>
  );
});
TableRow.displayName = 'TableRow';
TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['top', 'middle', 'bottom', 'baseline']),
  selectable: PropTypes.bool,
  isProcessing: PropTypes.bool,
  'aria-selected': PropTypes.bool,
};

const TableCellImpl = React.forwardRef((props, forwardedRef) => {
  const {
    align,
    allowCopy,
    allowOverflow = false,
    breakpoint,
    children,
    className,
    colSpan = 1,
    copyText,
    justify,
    onClick,
    onCopy,
    style,
    tag: Tag = 'td',
    width,
    withDividerAfter,
    withDividerBefore,
    ...cellProps
  } = props;

  const cellRef = React.useRef();
  const columnBreakpoint = useColumnBreakpoint(cellRef);
  const resolvedBreakpoint = breakpoint ?? columnBreakpoint ?? 'base';
  const visibleColumns = useVisibleColumns();

  const copyToClipboard = async (copyText) => {
    return navigator.clipboard.writeText(copyText);
  };

  const handleClick = allowCopy
    ? async () => {
        await copyToClipboard(copyText);
        if (onCopy) {
          onCopy();
        }
      }
    : (onClick ?? null);

  return (
    <Tag
      {...cellProps}
      colSpan={Math.min(colSpan, visibleColumns)}
      ref={mergeRefs(cellRef, forwardedRef)}
      className={cn(styles.tableCell, className)}
      data-allow-overflow={allowOverflow || null}
      data-with-divider-before={withDividerBefore || null}
      data-with-divider-after={withDividerAfter || null}
      data-breakpoint={resolvedBreakpoint}
      data-allow-copy={allowCopy || null}
      onClick={handleClick}
      style={{
        textAlign: justify,
        verticalAlign: align,
        width,
        ...style,
      }}>
      {children}
      {allowCopy && (
        <span className={styles.copyIcon}>
          <Copy />
        </span>
      )}
    </Tag>
  );
});
TableCellImpl.displayName = 'TableCellImpl';
TableCellImpl.propTypes = {
  align: PropTypes.oneOf(['top', 'middle', 'bottom', 'baseline']),
  allowCopy: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  breakpoint: breakpointPropType,
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  copyText: PropTypes.string,
  justify: PropTypes.oneOf(['start', 'center', 'end']),
  onClick: PropTypes.func,
  onCopy: PropTypes.func,
  style: PropTypes.object,
  tag: PropTypes.oneOf(['td', 'th']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDividerAfter: PropTypes.bool,
  withDividerBefore: PropTypes.bool,
};

const TableCell = React.forwardRef((props, forwardedRef) => (
  <TableCellImpl {...props} tag="td" ref={forwardedRef} />
));
TableCell.displayName = 'TableCell';
TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  justify: PropTypes.oneOf(['start', 'center', 'end']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDividerBefore: PropTypes.bool,
  withDividerAfter: PropTypes.bool,
};

const TableCellWithOverflow = React.forwardRef(
  ({ className, children, ...rest }, forwardedRef) => (
    <TableCellImpl
      className={cn(styles.tableCellWithOverflow, className)}
      tag="td"
      data-overflow-cell
      ref={forwardedRef}
      {...rest}>
      <RadixScrollArea.Root className={styles.tableCellWithOverflowScrollRoot}>
        <RadixScrollArea.Viewport>
          <div className={styles.tableCellWithOverflowInner}>{children}</div>
        </RadixScrollArea.Viewport>
        <RadixScrollArea.Scrollbar
          className={styles.tableCellWithOverflowScrollbar}
          orientation="horizontal">
          <RadixScrollArea.Thumb
            className={styles.tableCellWithOverflowThumb}
          />
        </RadixScrollArea.Scrollbar>
      </RadixScrollArea.Root>
    </TableCellImpl>
  ),
);
TableCellWithOverflow.displayName = 'TableCellWithOverflow';
TableCellWithOverflow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const TableColumnHeaderCell = React.forwardRef((props, forwardedRef) => (
  <TableCellImpl
    scope="col"
    align="middle"
    tag="th"
    ref={forwardedRef}
    className={cn(styles.tableColumnHeaderCell, props.className)}
    {...props}
  />
));
TableColumnHeaderCell.displayName = 'TableColumnHeaderCell';
TableColumnHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  justify: PropTypes.oneOf(['start', 'center', 'end']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDividerBefore: PropTypes.bool,
  withDividerAfter: PropTypes.bool,
};

const TableRowHeaderCell = React.forwardRef((props, forwardedRef) => (
  <TableCellImpl
    scope="row"
    {...props}
    tag="th"
    ref={forwardedRef}
    className={cn(styles.tableRowHeaderCell, props.className)}
  />
));
TableRowHeaderCell.displayName = 'TableRowHeaderCell';
TableRowHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  justify: PropTypes.oneOf(['start', 'center', 'end']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDividerBefore: PropTypes.bool,
  withDividerAfter: PropTypes.bool,
};

export {
  TableRoot,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableCellWithOverflow,
  TableColumnHeaderCell,
  TableRowHeaderCell,
};
