'use client';

import React, { useId } from 'react';
import PropTypes from 'prop-types';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';

import styles from './RadioGroup.module.css';

const RadioGroupItem = React.forwardRef(
  ({ children, value, ...props }, forwardedRef) => {
    const inputId = useId();
    return (
      <div className={styles.itemContainer} ref={forwardedRef}>
        <RadixRadioGroup.Item
          className={styles.radioGroupItem}
          value={value}
          id={inputId}
          {...props}>
          <RadixRadioGroup.Indicator className={styles.radioGroupIndicator} />
        </RadixRadioGroup.Item>
        <label className={styles.itemLabel} htmlFor={inputId}>
          {children}
        </label>
      </div>
    );
  },
);

RadioGroupItem.displayName = 'RadioGroupItem';
RadioGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioGroupItem;
