'use client';

import * as RadixDropdown from '@radix-ui/react-dropdown-menu';

import DropdownMenu from './DropdownMenu';
import DropdownItem from './DropdownItem';

const Dropdown = RadixDropdown.Root;
const DropdownTrigger = RadixDropdown.Trigger;

export { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem };
